import React, {FC} from "react";
import {Divider} from "antd";
import {DividerProps} from "antd/es/divider";


interface IProps {

}

const IDivider: FC<IProps & DividerProps> = (props) => {
  const {children, ...rest} = props
  return <Divider {...rest}>{children}</Divider>
}

export default IDivider
