import {IActionMethodType} from "../types";
import {actionTypes as types} from './index'
import {Dispatch} from "redux";
import {ArticleTag, getArticleTagInfo, getArticleTagList} from "../../services/articleTag";


export const handleArticleTagListByService = (params: any) => {
  return async (dispatch: Dispatch) => {
    let data = await getArticleTagList(params)
    if (data) {
      const {result} = data
      dispatch(setArticleList<Array<ArticleTag>>(result))
    }
  }
}

export const handleArticleTagInfoByService = (id: number) => {
  return async (dispatch: Dispatch) => {
    let data = await getArticleTagInfo(id)
    if (data) {
      const {result} = data
      dispatch(setArticleTagInfo<ArticleTag>(result))
    }
  }
}

const setArticleTagInfo: IActionMethodType = (data) => {
  return {
    type: types.SET_ARTICLE_TAG_INFO,
    payload: data
  }
}

const setArticleList: IActionMethodType = (data) => {
  return {
    type: types.SET_ARTICLE_TAG_LIST,
    payload: data
  }
}
