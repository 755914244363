// 当前 action 所有类型
export type ActionTypes = LOGIN | LOGOUT | REGISTER | SET_ADMIN_INFO | SET_LOGIN_STATUS

// 登陆
export const LOGIN = "admin/LOGIN";
export type LOGIN = typeof LOGIN

// 注册
export const REGISTER = "admin/REGISTER"
export type  REGISTER = typeof REGISTER

// 登出
export const LOGOUT = "admin/LOGOUT"
export type LOGOUT = typeof LOGOUT

// 设置 当前登陆 Admin 信息
export const SET_CURRENT_ADMIN_INFO = "admin/SET_CURRENT_ADMIN_INFO"
export type SET_ADMIN_INFO = typeof SET_CURRENT_ADMIN_INFO;

// 设置登陆状态
export const SET_LOGIN_STATUS = "admin/SET_LOGIN_STATUS"
export type SET_LOGIN_STATUS = typeof SET_LOGIN_STATUS
