// 适配

// .col-xs-12 .col-sm-6 .col-md-8
import {RowProps} from "antd/es/row";

export const gridLayout = {
  xs: {
    span: 12,
  },
  sm: {
    span: 6,
  },
  md: {
    span: 8,
  }
}

export const headerAvatarLayout = {
  xs: {
    offset: 20,
    span: 4
  },
  sm: {
    offset: 18,
    span: 6
  },
  md: {
    offset: 20,
    span: 4
  }
}

export const userCenterLeftCardLayout = {
  xs: 24,
  sm: 12,
  md: 7,
}

export const userCenterRightCardLayout = {
  xs: 24,
  sm: 12,
  md: 17,
}
export const commonRowAttr: RowProps = {
  gutter: [18, 18]
}

// const ItemLayout = {
//   lg: {span: 4},
//   sm: {span: 20},
// }

export const gutter = {xs: 8, sm: 16, md: 24}

export const formLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
    md: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 14},
    md: {span: 6},
  },
};

export const modalFormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
}

export const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
