import React, {useContext} from "react";
import {Layout, Menu} from "antd";
import {MenuContext} from "../../IContext/MenuContext";
import IMenu from "../../IMenu";

const {Sider} = Layout;

const ISider = () => {
  const menu = useContext(MenuContext)

  return <Sider
    breakpoint="lg"
    collapsedWidth="0"
    onBreakpoint={broken => {
      // console.log(broken);
    }}
    onCollapse={(collapsed, type) => {
      // console.log(collapsed, type);
    }}
  >
    <div className="logo"/>


    <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']}>
      {
        IMenu(menu)
      }
    </Menu>


  </Sider>
}

export default ISider
