import React, {FC, useEffect} from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {connect} from "react-redux";
import {isAuthenticated} from "../utils/auth";
import {Dispatch} from "redux";
import {getILocalStorageCurrentLoginUser} from "../store/admin/actionCreators";
import IBindActionCreators from "../store/IBindActionCreators";

export interface PrivateRouteIProps {
  component: any
  dispatch: Dispatch
}

const PrivateRoute: FC<PrivateRouteIProps & RouteProps> = (props) => {
  let {component: Component, dispatch, ...rest} = props;
  // 处理登陆信息
  const currentLoginUser = IBindActionCreators({
    actionCreators: {
      getILocalStorageCurrentLoginUser
    }, dispatch
  })

  useEffect(function () {
    currentLoginUser.getILocalStorageCurrentLoginUser()
  })

  return (
    <Route
      {...rest}
      render={props => isAuthenticated() === true ? <Component {...props} /> : <Redirect to='/login'/>}
    />)
}

const mapStateToProps = (state: any) => ({
  isLogin: state.getIn(['Admin', 'isLogin'])
})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
