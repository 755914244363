import React, {FC} from "react";
import {Col, Layout, Row} from "antd";
import IHeaderDropdown from "../IHeaderDropdown";
import {headerAvatarLayout} from "../../../utils/layout";


const {Header} = Layout;

interface IProps {

}

const IHeader: FC<IProps> = () => {
  return <Header className="site-layout-sub-header-background" style={{padding: 0}}>
    <Row>
      <Col {...headerAvatarLayout}>
        <IHeaderDropdown></IHeaderDropdown>
      </Col>
    </Row>
  </Header>
}

export default IHeader
