import {fromJS} from "immutable";
import {IAction} from "../types";
import {actionTypes as types} from "./index";


const defaultState = fromJS({
  info: fromJS({}),
})

//
export default (state = defaultState, action: IAction) => {
  const {type, payload} = action
  switch (type) {
    case types.SET_ACTION_ARTICLE_INFO:
      return setArticleInfo(state, payload)
    default:
      return state
  }
}
const setArticleInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}
