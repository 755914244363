import {post, ResponseData} from "../components/Axios";
import {pagesParamsType} from "./types";


export interface ArticleTag {
  id: number
  title: string
  color: string
  status: number
  createdAt: string
}


export type ArticleTagArr = Array<ArticleTag>


interface ArticleTagListParams extends pagesParamsType {
  status?: number,
}

/**
 * 获取 标签列表
 * @param params
 */
export const getArticleTagList = async (params: ArticleTagListParams): Promise<ResponseData<ArticleTagArr>> => {
  return post<ArticleTagArr>("admin/article_tag/index", params)
}

/**
 * 获取 详情
 * @param id
 */
export const getArticleTagInfo = async (id: number): Promise<ResponseData<ArticleTag>> => {
  return post<ArticleTag>("admin/article_tag/info", {id})
}

interface createArticleTagParams {
  title: string
  color: string
  status: number
}

/**
 * 创建
 * @param params
 */
export const createArticleTag = (params: createArticleTagParams) => {
  return post<void>("admin/article_tag/create", params)
}

interface updateArticleTagParams {
  id: number
  title: string
  color: string
  status: number
  created_at: string
}

/**
 * 修改
 * @param params
 */
export const updateArticleTag = (params: updateArticleTagParams) => {
  return post<void>("admin/article_tag/update", params)
}

/**
 * 删除
 * @param id
 */
export const delArticleTag = (id: number) => {
  return post<void>("admin/article_tag/delete", {id})
}


