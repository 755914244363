import React from "react";
import SubMenu from "antd/es/menu/SubMenu";
import {Link} from 'react-router-dom'
import {Menu} from "antd";

export interface IMenuType {
  title: string
  key: string
  children?: Array<IMenuType>
}

const IMenuItem = (item: IMenuType) => {
  return (
    <Menu.Item key={item.title}>
      <Link to={item.key}>
        <span>{item.title}</span>
      </Link>
    </Menu.Item>
  );
}

const IMenu = (list: Array<IMenuType>) => {
  return list && list.map(item => {
    if (item.children && item.children.length) {
      return <SubMenu
        key={item.title}
        title={
          <span>>
        <span></span>
        <span>{item.title}</span>
      </span>
        }
      >
        {
          item.children && IMenu(item.children)
        }
      </SubMenu>
    }
    return IMenuItem(item);
  })
}
export default IMenu
