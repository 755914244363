import React from 'react';

import ITable from "../../components/ITable";
const Test = () => {


  const columns = [
    {title: 'id', dataIndex: 'id', width: 120},
    {title: 'category', dataIndex: ['category', 'title'], width: 120},
    {title: 'libraryId', dataIndex: 'libraryId', width: 120},
    {title: 'title', dataIndex: 'title', width: 200},
    {title: 'author', dataIndex: ['user', 'username'], width: 120},
    // {title: 'description', dataIndex: 'description', width: 200},
    {title: 'hits', dataIndex: 'hits', width: 80},
    {title: 'isTop', dataIndex: 'isTop', width: 80},
  ]



  return <ITable columns={columns} />

};

export default Test
