import React from "react";
import {IMenuType} from "../IMenu";

export const listMenu: Array<IMenuType> = [
  {
    title: '首页',
    key: '/dashboard'
  },
  {
    title: '工作台',
    key: '/work-bench',
    children: []
  },
  {
    title: '个人页',
    key: '/user',
    children: [
      {
        title: '个人中心',
        key: '/user-center'
      },
      {
        title: '个人设置',
        key: '/user-settings'
      }
    ]
  },
  {
    title: "文章管理",
    key: "article",
    children: [
      {
        title: '文章分类',
        key: '/article/category'
      },
      {
        title: '文章标签',
        key: '/article/tag'
      },
      {
        title: '文章',
        key: '/article/index'
      },
    ]
  },
  {
    title: '用户管理',
    key: '/member',
    children: [
      {
        title: '用户账号',
        key: '/member/index'
      },
    ]
  },
  {
    title: '权限管理',
    key: '/admin/admin',
    children: [
      {
        title: '管理员账号',
        key: '/admin/index'
      },
      {
        title: '角色管理',
        key: '/admin/role'
      },
      {
        title: '权限管理',
        key: '/admin/permission'
      }
    ]
  },
  {
    title: '图书管理',
    key: '/library',
    children: [
      {
        title: '图书',
        key: '/library/index'
      }
    ]
  },
  {
    title: '系统配置',
    key: '/setting',
    children: [
      {
        title: '基本设置',
        key: '/setting/index'
      },
      {
        title: '友情链接',
        key: '/setting/link'
      },
      {
        title: '个人简历',
        key: '/setting/about-me'
      },
    ]
  },
];


export const MenuContext = React.createContext<Array<IMenuType>>({} as any)
