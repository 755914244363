import {isObject} from "../../utils/isType";

export enum ILocalStorageEnum {
  LoginCacheName = 'Login',
  LoginTimeCacheName = 'LoginTime',
}

type ILocalStorageType = {
  _storageHandler: Storage,
  constructor(): void,
  setItem(key: ILocalStorageEnum, value: string | Object): void,
  getItem(key: ILocalStorageEnum): any,
  removeItem(key: ILocalStorageEnum): void,
  clear(): void
}

export const ILocalStorage: ILocalStorageType = {
  _storageHandler: localStorage,
  constructor() {
    if (this._storageHandler == null) {
      this._storageHandler = localStorage
    }
  },
  setItem(key, value) {
    if (isObject(value)) {
      value = JSON.stringify(value)
    }
    this._storageHandler!.setItem(key, value as string)
  },
  getItem(key) {
    let value = this._storageHandler!.getItem(key)
    if (value != null) return JSON.parse(value)
    return value
  },
  removeItem(key) {
    this._storageHandler!.removeItem(key)
  },
  clear() {
    this._storageHandler!.clear()
  },
}
