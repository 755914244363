import React, {FC} from "react";
import {UserOutlined} from '@ant-design/icons';
import {Avatar} from "antd";
import {AvatarProps} from "antd/es/avatar";

interface IProps {

}

const IAvatar: FC<IProps & AvatarProps> = ({children, ...rest}) => {
  return <Avatar size={32} icon={<UserOutlined/>} {...rest}>{children}</Avatar>
}

export default IAvatar
