import {Dispatch} from "redux";
import {accountLogin, Admin, LoginParamsTypes} from "../../services/admin";
import {actionTypes as types} from "./index"
import md5 from "js-md5";
import {IActionMethodType} from "../types";
import IMessage from "../../components/IMessage";
import {
  getCurrentLoginUser,
  isAuthenticated,
  removeCurrentLoginUser,
  setCurrentLoginUser,
  setLoginExpireTime,
} from "../../utils/auth";

// 登陆
export const login: (params: LoginParamsTypes) => void = (params) => {
  return async (dispatch: Dispatch) => {
    params = {...params, loginPwd: md5(params.loginPwd)}
    let data = await accountLogin(params)
    if (data) {
      const {result} = data
      // 缓存 当前用户
      setCurrentLoginUser(result)

      setLoginExpireTime()

      dispatch(setCurrentAdmin<Admin>(result))

      dispatch(setLoginStatus<boolean>(true))

      IMessage("登陆成功")
    }
  }
}

// 走本地缓存获取 信息
export const getILocalStorageCurrentLoginUser = () => {
  return (dispatch: Dispatch) => {
    if (isAuthenticated()) {
      dispatch(setCurrentAdmin(getCurrentLoginUser()))
      dispatch(setLoginStatus(true))
    }
  }
}

// 退出登陆
export const logout = () => {
  return async (dispatch: Dispatch) => {
    removeCurrentLoginUser()
    dispatch(setCurrentAdmin<any>([]))
    dispatch(setLoginStatus<boolean>(false))
  }
}

// 设置用户信息
const setCurrentAdmin: IActionMethodType = (data) => {
  return {
    type: types.SET_CURRENT_ADMIN_INFO,
    payload: data
  }
}

const setLoginStatus: IActionMethodType = (data) => {
  return {
    type: types.SET_LOGIN_STATUS,
    payload: data
  }
}
