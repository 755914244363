import {combineReducers} from 'redux-immutable'

import {reducer as AdminReducer} from "./admin"
import {reducer as ArticleTagReducer} from './articleTag'
import {reducer as ArticleCategoryReducer} from './articleCategory'
import {reducer as ArticleReducer} from './article'


const reducer = combineReducers({
  Admin: AdminReducer,
  ArticleTag: ArticleTagReducer,
  ArticleCategory: ArticleCategoryReducer,
  Article: ArticleReducer,
})

export default reducer




