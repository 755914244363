import {Dispatch} from "redux";
import {IActionMethodType} from "../types";
import {actionTypes as types} from "./index"
import {getArticleInfo} from "../../services/article";

export const handleArticleInfoByService = (id: number) => {
  return async (dispatch: Dispatch) => {
    let data = await getArticleInfo(id)
    if (data) {
      const { result } = data
      dispatch(setArticleInfo(result))
    }
  }
}

export const setArticleInfo: IActionMethodType = (data) => {
  return {
    type: types.SET_ACTION_ARTICLE_INFO,
    payload: data
  }
}
