import Axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import Qs from "qs"
import IMessage, {messageEnum} from "../IMessage";
import {SUCCESS_CODE, SUCCESS_HTTP_STATUS, TOKEN_ERROR_CODE, TOKEN_TIMEOUT_CODE} from "../../config/errorCode";
import {getToken, isAuthenticated} from "../../utils/auth";
import store from "../../store";
import {logout} from "../../store/admin/actionCreators";

const BASE_URL = "https://api.shenfengshan.com"
// const BASE_URL = "http://127.0.0.1:9999"

/**
 * pages 返回格式
 */
export interface Pages {
  current: number,
  pageSize: number,
  total: number
}

/**
 * Axios 返回格式
 */
export interface ResponseData<T = []> {
  code: number,
  message: string,
  result: T,
  page: Pages
  reqId: string
}

const instance = Axios.create({});


instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // post
    if (config.method!.toLowerCase() === "post") {
      isAuthenticated() && (config.headers.Authorization = getToken())
    }
    return config
  },
  (error: AxiosError) => Promise.reject(error)
)


instance.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    const {data, status} = response
    const {code, message} = data
    if (status === SUCCESS_HTTP_STATUS) {
      switch (code) {
        case SUCCESS_CODE:
          return data as any;
          break
        case TOKEN_ERROR_CODE :
        case TOKEN_TIMEOUT_CODE :
          // todo token 验证失败
          store.dispatch<any>(logout())
          IMessage(message, messageEnum.ERROR)
          break
        default:
          console.log("验证失败")
          IMessage(message, messageEnum.ERROR)
          break
      }
      return null
    } else {
      IMessage(message, messageEnum.ERROR)
    }
  },
  (error: AxiosError) => {
    IMessage("服务器错误", messageEnum.ERROR)
    return null
  }
)

/**
 * post 请求
 * @param url
 * @param params
 * @param config
 */
export function post<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<ResponseData<T>> {
  return instance.post<T, ResponseData<T>>(url, Qs.stringify(params), {baseURL: BASE_URL, ...config})
}

/**
 * post 上传文件
 * @param url
 * @param params
 * @param config
 */
export function upload<T>(url: string, params?: any, config?: AxiosRequestConfig): Promise<ResponseData<T>> {
  return instance.post<T, ResponseData<T>>(url, params, {
    baseURL: BASE_URL,
    headers: {'Content-Type': 'multipart/form-data'}, ...config
  })
}
