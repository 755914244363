import {fromJS} from "immutable";
import {IAction} from "../types";
import {actionTypes as types} from './index'

const defaultState = fromJS({
  currentUser: fromJS({}),
  isLogin: false,
})

export default (state = defaultState, action: IAction) => {
  const {type, payload} = action
  switch (type) {
    case types.SET_CURRENT_ADMIN_INFO:
      return setCurrentUser(state, payload)
      break
    case types.SET_LOGIN_STATUS:
      return setLoginStatus(state, payload)
      break
    default:
      return state
  }
}

const setCurrentUser = (state: any, payload: any) => {
  return state.merge({
    currentUser: fromJS(payload)
  })
}

const setLoginStatus = (state: any, payload: any) => {
  return state.merge({
    isLogin: payload
  })
}
