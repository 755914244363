import React, {FC, useContext} from "react";
import {Dropdown} from "antd";
import DropdownMenu from "./DropdownMenu";
import {DownOutlined} from "@ant-design/icons/lib";
import './index.css'
import {HeaderDropdownCbsContext, HeaderDropdownCbsContextType} from "../../IContext/HeaderDropdownCbsContext";
import IAvatar from "../../IAvatar";

interface IProps {

}

const IHeaderDropdown: FC<IProps> = (props) => {
  const {headerDropdownCbs, currentLoginUser} = useContext<HeaderDropdownCbsContextType>(HeaderDropdownCbsContext)

  return <Dropdown overlay={<DropdownMenu {...headerDropdownCbs as any}/>}>
    <span className="dropdown-link">
       <IAvatar/>
      {true && <span>&nbsp; {currentLoginUser && currentLoginUser.username} <DownOutlined/></span>}
    </span>
  </Dropdown>

}

export default IHeaderDropdown
