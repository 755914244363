import React, {FC, useMemo} from "react"
import LoginForm from "./components/LoginForm"
import './index.css'
import {connect} from "react-redux"
import {bindActionCreators, Dispatch} from "redux"
import {Col, Row} from "antd"
import {Redirect} from "react-router-dom";
import {login} from "../../store/admin/actionCreators";

interface IProps {
  dispatch: Dispatch
  isLogin: boolean
}

const Login: FC<IProps> = (props) => {
  const {dispatch, isLogin} = props

  // loginForm
  const loginFormCbs = useMemo(function () {
    return bindActionCreators({
      handleOnLogin: login,
    }, dispatch)
  }, [])

  return <Row className="login-container bg-image">
    <Col span={6} className="login-form-container">
      <div>
        <h3>账号登陆</h3>
        <p>请输入您的账号密码</p>
      </div>
      <LoginForm {...loginFormCbs}></LoginForm>
    </Col>
    {isLogin && <Redirect to='/home'/>}
  </Row>
}

const mapStateToProps = (state: any) => ({
  isLogin: state.getIn(['Admin', 'isLogin'])
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {dispatch}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
