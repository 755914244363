import React, {FC, useMemo} from "react";
import {Layout} from 'antd';
import IFooter from "../components/Layouts/IFooter";
import IHeader from "../components/Layouts/IHeader";
import ISider from "../components/Layouts/ISider";
import "./adminLayout.css"
import IBreadcrumb from "../components/Layouts/IBreadcrumb";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {Admin} from "../services/admin";
import {logout} from "../store/admin/actionCreators";
import {HeaderDropdownCbsContext} from "../components/IContext/HeaderDropdownCbsContext";
import {listMenu, MenuContext} from "../components/IContext/MenuContext";
import IDivider from "../components/IDivider";


const {Content} = Layout;

interface IProps {
  dispatch: Dispatch
  currentLoginUser: Admin
}

const AdminLayout: FC<IProps> = (props) => {
  const {children, currentLoginUser, dispatch} = props

  const headerDropdownCbs = useMemo(function () {
    return bindActionCreators({
      handleUserLogout: logout
    }, dispatch)
  }, [])

  return (
    <Layout className="adminLayout-container">
      <MenuContext.Provider value={listMenu}>
        <ISider/>
      </MenuContext.Provider>
      <Layout>
        <HeaderDropdownCbsContext.Provider value={{headerDropdownCbs, currentLoginUser}}>
          <IHeader/>
        </HeaderDropdownCbsContext.Provider>
        <Content style={{margin: '24px 16px 0'}}>
          <div className="site-layout-background" style={{padding: 24, minHeight: "100%"}}>
            <IBreadcrumb/>
            <IDivider/>
            {children}
          </div>
        </Content>
        <IFooter/>
      </Layout>
    </Layout>
  )
}
const mapStateToProps = (state: any) => ({
  currentLoginUser: state.getIn(['Admin', 'currentUser']).toJS(),
})

const mapDispatchToProps = (dispatch: any) => {
  return {dispatch}
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout)
