export enum isTypeEnum {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Symbol = 'Symbol',
  Undefined = 'Undefined',
  Null = 'Null',
  Array = 'Array',
  Object = 'Object',
}

// 判断类型
const isType = (type: isTypeEnum): Function => {
  return (value: any) => Object.prototype.toString.call(value) === `[object ${type}]`
}

export const isObject = isType(isTypeEnum.Object)

export const isArray = isType(isTypeEnum.Array)

export const isBoolean = isType(isTypeEnum.Boolean)

export const isString = isType(isTypeEnum.String)

export const isNumber = isType(isTypeEnum.Number)

export const isNull = isType(isTypeEnum.Null)
