import {post, ResponseData} from "../components/Axios";

export interface Admin {
  id: number
  username: string,
  email: string | null
  status: number,
  lastLoginTime: string
  lastLoginIp: string
  updatedAt: string
  createdAt: string
  token: string
}

export interface MinimalismAdmin {
  id: number
  username: string,
}

// 登陆参数
export interface LoginParamsTypes {
  loginName: string
  loginPwd: string
}

/**
 * 登陆
 * @LoginParamsTypes params
 */
export const accountLogin = async (params: LoginParamsTypes): Promise<ResponseData<Admin>> => {
  return post<Admin>("admin/admin/login", params)
}
