import {MinimalismArticleCategory} from "./articleCategory";
import {MinimalismAdmin} from "./admin";
import {post} from "../components/Axios";

export interface Article {
  coverDetail: any;
  id: number
  categoryId: number
  category: MinimalismArticleCategory
  libraryId: number
  userId: number
  user: MinimalismAdmin
  title: string
  cover: number
  description: string
  hits: number
  live: number
  sort: number
  isTop: number
  status: number
  createdAt: string
}


interface ArticleListParams {

}

export const getArticleList = async (params: ArticleListParams) => {
  return post<Array<Article>>("admin/article/index", params)
}

export const getArticleInfo = async (id: number) => {
  return post<Article>("admin/article/info", {id})
}

interface createArticleParams {
  categoryId: number
  title: string
  cover: number
  description: string
  content: string
  tagIds: Array<number>
  libraryId: number
  isTop: number
  status: number
  sort: number
}

export const createArticle = (params: createArticleParams) => {
  return post<void>("admin/article/create", params)
}

interface updateArticleParams {
  id: number
  categoryId: number
  title: string
  cover: number
  description: string
  content: string
  tagIds: Array<number>
  libraryId: number
  isTop: number
  status: number
  sort: number
}

export const updateArticle = (params: updateArticleParams) => {
  return post<void>("admin/article/update", params)
}

export const delArticle = (id: number) => {
  return post<void>("admin/article/delete", {id})
}
