import React, {FC} from "react";
import {TableProps} from "antd/es/table";
import {Table} from "antd";

interface IProps {

}

const ITable: FC<IProps & TableProps<any>> = ({children, ...rest}) => {
  return <Table {...rest}>
    {children}
  </Table>
}
export default ITable
