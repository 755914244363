import {useMemo} from "react";
import {ActionCreatorsMapObject, bindActionCreators, Dispatch} from "redux";


interface IProps {
  dispatch: Dispatch
  actionCreators: ActionCreatorsMapObject
  deps?: Array<any>
}

const IBindActionCreators = ({actionCreators = {}, dispatch, deps = []}: IProps) => {
  return useMemo(function () {
    return bindActionCreators(actionCreators, dispatch)
  }, deps)
}

export default IBindActionCreators
