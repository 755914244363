import React, {Component} from 'react';
import {ConfigProvider} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import {Provider} from "react-redux";
import store from "./store";
import ERouter from "./router/ERouter";
import './asset/css/app.css'

class App extends Component<any, any> {

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log("App componentDidCatch", error, errorInfo)
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <ERouter/>
        </Provider>
      </ConfigProvider>
    )
  }
}

export default App;
