import {ILocalStorage, ILocalStorageEnum} from "../components/ILocalStorage";
import {Admin} from "../services/admin";
import store from "../store";
import {logout} from "../store/admin/actionCreators";


export const setLoginExpireTime = () => {
  ILocalStorage.setItem(ILocalStorageEnum.LoginTimeCacheName, new Date().getTime() + 3600000)
}


export const getLoginExpireTime = () => {
  return ILocalStorage.getItem(ILocalStorageEnum.LoginTimeCacheName)
}

const isExpire = () => {
  return (new Date()).getTime() < getLoginExpireTime()
}

export const isAuthenticated = () => {
  !isExpire() && store.dispatch<any>(logout())
  return getCurrentLoginUser() != null
}

export const setCurrentLoginUser = (value: Admin) => {
  return ILocalStorage.setItem(ILocalStorageEnum.LoginCacheName, value)
}

export const getCurrentLoginUser = (): Admin => {
  return ILocalStorage.getItem(ILocalStorageEnum.LoginCacheName) as Admin
}

export const removeCurrentLoginUser = () => {
  return ILocalStorage.removeItem(ILocalStorageEnum.LoginCacheName)
}

export const getToken = () => {
  return getCurrentLoginUser().token;
}
