import {fromJS} from "immutable";
import {IAction} from "../types";
import {actionTypes as types} from './index'

const defaultState = fromJS({
  info: fromJS({}),
  list: fromJS([]),
})


export default (state = defaultState, action: IAction) => {
  const {type, payload} = action
  switch (type) {
    case types.SET_ARTICLE_TAG_INFO:
      return setArticleTagInfo(state, payload)
    case types.SET_ARTICLE_TAG_LIST:
      return setArticleTagList(state, payload)
    default:
      return state
  }
}

const setArticleTagList = (state: any, payload: any) => {
  return state.merge({
    list: fromJS(payload)
  })
}
const setArticleTagInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}


