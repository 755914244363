import React, {FC, Suspense} from "react";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import AdminLayout from "./AdminLayout";

import ILoading from "../components/ILoading";
import Index from "../pages/Login";
import PrivateRoute from "./PrivateRoute";
import Test from '../pages/Test'

const Dashboard = React.lazy(/* webpackChunkName: "dashboard" */ () => import('../pages/Dashboard'))
const UserCenter = React.lazy(/* webpackChunkName: "userCenter" */ () => import('../pages/UserCenter'))
const ArticleTag = React.lazy(/* webpackChunkName: "articleTag" */ () => import('../pages/ArticleTag'))
const ArticleCategory = React.lazy(/* webpackChunkName: "articleCategory" */ () => import('../pages/ArticleCategory'))
const Article = React.lazy(/* webpackChunkName: "article" */ () => import('../pages/Article'))
const Library = React.lazy(/* webpackChunkName: "Library" */ () => import('../pages/Library'))

type IProps = {}

const ERouter: FC<IProps> = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Index}></Route>
        <Route exact path="/r" component={ILoading}></Route>
        <PrivateRoute path="/" component={() =>
          <AdminLayout>
            <Suspense fallback={<ILoading/>}>
              <Route exact path="/test" component={Test}></Route>
              <Route exact path="/dashboard" component={Dashboard}></Route>
              <Route exact path="/user-center" component={UserCenter}></Route>
              <Route exact path="/article/tag" component={ArticleTag}></Route>
              <Route exact path="/article/category" component={ArticleCategory}></Route>
              <Route exact path="/article/index" component={Article}></Route>
              <Route exact path="/library/index" component={Library}></Route>
              {/*<Route exact path="/login1" component={Login1}></Route>*/}
            </Suspense>
          </AdminLayout>
        }/>


      </Switch>
    </BrowserRouter>
  )
}
export default ERouter
