import {message} from "antd";
import * as React from "react";

message.config({
  duration: 2,
  maxCount: 3,
})

export enum messageEnum {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WAINING = 'warning',
  LOADING = 'loading',
}


type IMessageType = (content: React.ReactNode, type?: messageEnum) => void

/**
 *  Message 封装
 * @param content
 * @param type
 * @constructor
 */
const IMessage: IMessageType = (content, type = messageEnum.SUCCESS) => {
  message.destroy()
  message[type](content)
}
export default IMessage
