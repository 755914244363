import {fromJS} from "immutable";
import {IAction} from "../types";
import {actionTypes as types} from './index'


const defaultState = fromJS({
  treeSelect: fromJS([]),
  info: fromJS({}),
})


export default (state = defaultState, action: IAction) => {
  const {type, payload} = action
  switch (type) {
    case types.SET_ACTION_CATEGORY_TREE_SELECT:
      return setArticleCategoryTreeSelect(state, payload)
    case  types.SET_ACTION_CATEGORY_INFO:
      return setArticleCategoryInfo(state, payload)
    default:
      return state
  }
}

const setArticleCategoryTreeSelect = (state: any, payload: any) => {
  return state.merge({
    treeSelect: fromJS(payload)
  })
}

const setArticleCategoryInfo = (state: any, payload: any) => {
  return state.merge({
    info: fromJS(payload)
  })
}
