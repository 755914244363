import React, {FC} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {LogoutOutlined, SettingOutlined, UserOutlined} from '@ant-design/icons';


interface IProps {
  handleUserLogout: Function
}

const DropdownMenu: FC<IProps> = (props) => {
  const {handleUserLogout} = props
  return <Menu>
    <Menu.Item key="0">
      <Link to={'/user-center'}>
        <UserOutlined/>
        <span>个人中心</span>
      </Link>
    </Menu.Item>
    <Menu.Item key="1">
      <Link to={'/user-settings'}>
        <SettingOutlined/>
        <span>个人设置</span>
      </Link>
    </Menu.Item>
    <Menu.Divider/>
    <Menu.Item key="3" onClick={() => handleUserLogout()}>
      <LogoutOutlined/>
      <span>退出登陆</span>
    </Menu.Item>
  </Menu>
}
export default DropdownMenu
