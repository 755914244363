import {post} from "../components/Axios";

export interface ArticleCategory {
  id: number
  parentId: number
  title: string
  description: string
  sort: number
  status: number
  createdAt: string
  value?: number
  children?: ArticleCategoryArr
}

export interface MinimalismArticleCategory {
  id: number
  title: string
}

export type ArticleCategoryArr = Array<ArticleCategory>

export const articleCategoryTreeSelectTopLevel = {
  title: "请选择分类",
  value: 0,
  createdAt: "",
  description: "",
  id: 0,
  parentId: 0,
  sort: 0,
  status: 0
}

export const buildArticleCategoryTreeSelectData = (data: ArticleCategoryArr, pid = 0) => {
  let arr: ArticleCategoryArr = []

  data.map((item, index) => {
    if (item.parentId === pid) {
      let temp = item
      // delete (data[index])
      temp.value = temp.id
      temp.children = buildArticleCategoryTreeSelectData(data, item.id)
      arr.push(temp)
    }
  })
  return arr
}

interface ArticleCategoryListParams {

}

export const getArticleCategoryList = async (params: ArticleCategoryListParams) => {
  return post<ArticleCategoryArr>("admin/article_category/index", params)
}

export const getArticleCategoryInfo = (id: number) => {
  return post("admin/article_category/info", {id})
}

interface createArticleCategoryParams {
  title: string
  description: string
  sort: number
  status: number
  parentId: number
}

export const createArticleCategory = (params: createArticleCategoryParams) => {
  return post<void>("admin/article_category/create", params)
}

interface updateArticleCategoryParams {
  id: number
  title: string
  description: string
  sort: number
  status: number
  parentId: number
}

export const updateArticleCategory = (params: updateArticleCategoryParams) => {
  return post<void>("admin/article_category/update", params)
}

export const delArticleCategory = (id: number) => {
  return post<void>("admin/article_category/delete", {id})
}



