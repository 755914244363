import React, {FC} from "react";
import {Breadcrumb} from 'antd';

const IBreadcrumb: FC = () => {
  return <Breadcrumb>
    <Breadcrumb.Item>Ant Design</Breadcrumb.Item>
    <Breadcrumb.Item>1</Breadcrumb.Item>
    <Breadcrumb.Item>2</Breadcrumb.Item>
    <Breadcrumb.Item>3</Breadcrumb.Item>
  </Breadcrumb>
}

export default IBreadcrumb
