import React, {FC} from "react";
import {Button, Form, Input} from "antd"
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import './index.css'

interface IProps {
  handleOnLogin: (values: any) => void
}

const LoginForm: FC<IProps> = (props) => {
  const {handleOnLogin} = props

  return <Form
    className="login-form"
    initialValues={{}}
    onFinish={handleOnLogin}
  >

    <Form.Item
      name="loginName"
      rules={[{required: true, message: 'Please input your Username!'}]}
    >
      <Input
        size={"large"}
        prefix={<UserOutlined className="site-form-item-icon"/>}
        placeholder="Username"
      />
    </Form.Item>

    <Form.Item
      name="loginPwd"
      rules={[{required: true, message: 'Please input your Password!'}]}
    >
      <Input
        size={"large"}
        prefix={<LockOutlined className="site-form-item-icon"/>}
        type="password"
        placeholder="Password"
      />
    </Form.Item>

    <Form.Item>
      <Button
        size={"large"}
        type="primary"
        htmlType="submit"
        className="login-form-button"
      >
        Log in
      </Button>
    </Form.Item>
  </Form>
}

export default LoginForm
