import React from "react";
import {Spin} from "antd";
import './index.css'

const ILoading = () => {
  return <div className="loading">
    <Spin/>
    <span> 加载中，请稍后...</span>
  </div>
}

export default ILoading
