import {Dispatch} from "redux";
import {actionTypes as types} from './index'
import {
  articleCategoryTreeSelectTopLevel,
  buildArticleCategoryTreeSelectData,
  getArticleCategoryInfo,
  getArticleCategoryList
} from "../../services/articleCategory";


export const getArticleCategoryTreeSelectByService = (params = []) => {
  return async (dispatch: Dispatch) => {
    let data = await getArticleCategoryList(params)
    if (data) {
      const {result} = data
      let res = buildArticleCategoryTreeSelectData(result)
      res.unshift(articleCategoryTreeSelectTopLevel)
      dispatch(setArticleCategoryTreeSelect(res))
    }
  }
}


export const getArticleCategoryInfoByService = (id: number) => {
  return async (dispatch: Dispatch) => {
    const data = await getArticleCategoryInfo(id)
    if (data) {
      const {result} = data
      dispatch(setArticleCategoryInfo(result))
    }
  }
}


const setArticleCategoryTreeSelect = (data: any) => {
  return {
    type: types.SET_ACTION_CATEGORY_TREE_SELECT,
    payload: data
  }
}


const setArticleCategoryInfo = (data: any) => {
  return {
    type: types.SET_ACTION_CATEGORY_INFO,
    payload: data
  }
}
